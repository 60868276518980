<template>
	<div>
		<div class="container">
			<div class="logo">
				<a href="https://mrt-m24.com" title="Перейти на сайт"><img src="@/assets/logo.webp" alt="m24 logo"></a>
			</div>
			<component v-if="!submitSucces" :is="activeBlock" :formData="formData" @next-step="nextStep" @prev-step="prevStep" @submit="submitForm"></component>
			<Success v-else></Success>
			<ProgressBar :activeBlock="activeBlock" :count="3"></ProgressBar>
		</div>
	</div>
</template>
<script>
import Step1 from '@/components/PatientForm/Step1.vue'
import Step2 from '@/components/PatientForm/Step2.vue'
import Step3 from '@/components/PatientForm/Step3.vue'
import Success from '@/components/Success.vue'
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  name: 'PatienFormView',
  components: {
		Step1,
		Step2,
		Step3,
		Success,
		ProgressBar
  },
	data() {
		return {
			activeBlock: "Step1",
			submitSucces: false,
			formData: {}
		}
	},
	mounted() {
		// const params = new URLSearchParams(window.location.search);
		// this.formData.location = params.get('loc');
		this.formData.location = this.$route?.query?.loc || null;
	},
	methods: {
		nextStep(data) {
      this.formData = { ...this.formData, ...data };
      if (this.activeBlock === 'Step1') {
        this.activeBlock = 'Step2';
      } else if (this.activeBlock === 'Step2') {
        this.activeBlock = 'Step3';
      }
    },
		prevStep() {
			if (this.activeBlock === 'Step2') {
        this.activeBlock = 'Step1';
      } else if (this.activeBlock === 'Step3') {
        this.activeBlock = 'Step2';
      }
		},
		async submitForm(data) {
			this.formData.formname = 'feedback'
			const requestOptions = {
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify(this.formData)
					};
			const response = await fetch("api.php", requestOptions);
			const datas = await response.json();
			if (datas.error.length > 0) {
				console.log(datas.error);
			} else {
				if (datas.data == 1) {
					this.submitSucces = true
					this.activeBlock = 'Step1'
					this.formData = {}
				}
			}



    },
	}
}
</script>

<style lang="sass">
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap")
*
	margin: 0
	padding: 0
	box-sizing: border-box
$font: 'Open Sans', sans-serif
$color: #2f3542
.container
	width: 100%
	max-width: 640px
	margin: 0 auto
body
	font-family: $font
	font-size: 16px
	font-weight: 400
	color: $color
	background-color: RGB(248,249,250)
.h1
	font-weight: 700
.formbox
	position: relative
	padding: 1.5em
	width: 100%
	background-color: #fff
	border-radius: 5px
	box-shadow: 0 10px 15px 5px rgba(0,0,0,0.05)
	margin-bottom: 2em
	.h1
		text-align: center
		margin-top: 0
		font-size: 24px
		line-height: 1
		font-weight: 700
	.formbox-title
		font-weight: 700
		font-size: 22px
		margin-bottom: 25px
.subtitle
	display: block
	line-height: 1.5
	// text-align: justify
	color: #5a667b
	font-size: 14px
	margin: 1.5em 0
	// color: #7f8c8d
p
	margin: 10px 0
.buttongroup
	display: flex
	// justify-content: flex-end
	// align-items: flex-end
	margin-top: 1.75em
	position: relative
	// text-align: center
	button
		padding: 0.75em 1.25em
		font-weight: 600
		// background-color: #2ecc71
		background-color: #2d3436
		color: #fff
		border: none
		text-transform: uppercase
		border: 1px solid rgba(#bdc3c7, 0.25)
		border-radius: 3px
		// margin: 0 5px
		box-shadow: 0 10px 15px 5px rgba(0,0,0,0.05)
		cursor: pointer
		&:hover,
		&:focus
			// background-color: darken(#2ecc71, 5%)
			background-color: darken(#2d3436, 5%)
.logo
	text-align: center
	margin: 1em 0
	img
		width: 100px
</style>
