<template>
	<div>
		<div class="block block-1">

			<div class="formbox">
				<h1 class="h1">Відгук про М24</h1>
				<div class="subtitle">
					<p>Шановний відвідувач М24! </p>
			Нам важлива Ваша оцінка якості обслуговування та комфортності перебування у діагностичному центрі. Це допомагає покращувати нашу роботу.
					<p>З повагою <br>
			Команда центрів МРТ та КТ діагностики М24</p>
				</div>

				<Textarea label="Тут Ви можете залишити відгук про нашу роботу (заради чого Ви сканували QR-код)" v-model="formData.msg" :value="formData.msg"></Textarea>

				<!-- <input type="tel" v-mask="' +38(0##) ###-##-##'" placeholder="+38(0__) ___-__-__"/> -->

				<div class="buttongroup">
					<button @click="validateStep">вперед</button>
				</div>

			</div>

		</div>





	</div>
</template>
<script>
import Textarea from '@/components/TextareaBlock.vue';
import Input from '@/components/InputBlock.vue';

export default {
  name: 'Step1',
	components: {
		Textarea,
		Input
	},

	props: ['formData'],
	data() {
		return {
			// formData: {

      // },
		}
	},
	methods: {
		validateStep() {
      // Перевірка полів кроку 2
      // if (this.formData.question1) {
        this.$emit('next-step', this.formData);
      // } else {
        // alert('Будь ласка, заповніть всі поля.');
      // }
    },
	}
}
</script>
<style lang="sass" scoped>
.buttongroup
	justify-content: flex-end
</style>