<template>
	<div>
		<div class="block block-1">

			<div class="formbox">
				<h1 class="h1">Тільки для співробітників М24</h1>
				<div class="subtitle">
					<p>
						Розвиваючи М24 ми мріємо, щоб кожен співробітник мав можливість досягти найкращих результатів та отримувати задоволення від роботи. Будемо вдячні, якщо ви допоможете нам в цьому.
					</p>
					<p>
						В цій формі можна написати про те, що вам подобається у вашій роботі або про те, що бажано змінити. Ви можете поділитися вашими особистими досягненнями чи досягненнями ваших колег, які вважаєте за необхідне розповісти керівництву або передати в публікацію в корпоративному телеграм-каналі.
					</p>
					<p>
						Форму можна заповнювати безліч разів – кожного разу, коли виникає бажання нею скористатись.
					</p>
					<p>
						Відгук є анонімним.
					</p>
					<p>
						Дякуємо :)
					</p>
				</div>

				<Textarea label="Запитання" v-model="formData.msg" :value="formData.msg"></Textarea>

				<RadioBlock
					label="Оцініть рівень Вашої задоволеності роботою в М24"
					name="so1"
					v-model="formData.selectedOption1"
					:error-message="!formData.selectedOption1 && isFieldRequired('selectedOption1') && stepValidate ? 'Відповідь на це запитання обов\'язкова' : ''"
				>
				</RadioBlock>

				<div class="buttongroup">

				<button @click="validateStep">відправити</button>
				</div>

			</div>

		</div>

	</div>
</template>
<script>
import Textarea from '@/components/TextareaBlock.vue';
import RadioBlock from '@/components/RadioBlock.vue';
export default {
  name: 'CompanyFormStep1',
	components: {
		Textarea,
		RadioBlock
	},
	props: ['formData'],
	data() {
		return {
			// formData: {},
			stepValidate: false,
			reqvalidate: ['selectedOption1']
		}
	},
	methods: {
		isFieldRequired(fieldName) {
      return this.reqvalidate.includes(fieldName);
    },
		// stepPrevius() {
		// 	this.$emit('prev-step')
		// },
		validateStep() {
			this.stepValidate = true

			const countValidate = this.reqvalidate.reduce((acc, item) => {
				let formData = this.formData
				if (formData[item]) {
					return acc + 1;
				} else {
					return acc;
				}
			}, 0);
			if (countValidate === this.reqvalidate.length) {
				// this.$emit('next-step', this.formData)
				// console.log('subm')
				this.$emit('submit', this.formData)
			}

    },
	}
}
</script>
<style lang="sass" scoped>
.field:not(:last-child)
	margin-bottom: 25px
.buttongroup
	justify-content: flex-end
</style>