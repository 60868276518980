import { createRouter,createWebHistory, createWebHashHistory } from 'vue-router'
import PatientView from '../views/PatientFormView'
import StartView from '../views/StartView'
import CompanyFormView from '../views/CompanyFormView'

const routes = [
	{
		path: '/',
		name: 'index',
		component: StartView
	},
	{
		path: '/feedback',
		name: 'feedback',
		component: PatientView
	},
	{
		path: '/form',
		name: 'companyform1',
		component: CompanyFormView
	}
]

const router = createRouter({
	base: process.env.BASE_URL,
	// mode: 'history',
  // history: createWebHashHistory(),
	history: createWebHistory(),
  routes,
	scrollBehavior(to, from, savedPosition) {
		// always scroll to top
		return { top: 0 }
	},
})

export default router
