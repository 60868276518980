<template>
	<div>
		<div class="block block-success">
			<div class="formbox">
				<h4 class="title">
					Дякуємо! За кілька хвилин Ваш відгук буде передано в опрацювання.
				</h4>
				<button @click="this.$router.go(0)">Заповнити ще</button>

			</div>

		</div>
	</div>
</template>

<script>
export default {
	name: "Success"
}
</script>
<style lang="sass" scoped>
.title
	// color: #5a667b
	font-weight: 600
	font-size: 20px
	margin: 15px 0
.block-success
	text-align: center
button
	text-decoration: underline
	background: none
	border: none
	box-shadow: none
	cursor: pointer
	color: #2980b9

</style>