<template>
	<div>

		<div class="block block-2">
			<div class="formbox">
				<div class="formbox-title">Оцініть балами</div>
				<RadioBlock
					label="ЗРУЧНІСТЬ ПРОЦЕСУ ЗАПИСУ на обстеження через контакт-центр<br> (1- найнижчий бал, 10 - найвищий бал)"
					name="so1"
					v-model="formData.selectedOption1"
				>
				</RadioBlock>
				<!-- <hr> -->
				<RadioBlock
					label="ЛЕГКО знайти АДРЕСУ та визначити МАРШРУТ до діагностичного центру<br> (1 - дуже важко, 10 - дуже легко)"
					name="so2"
					v-model="formData.selectedOption2"
				>
				</RadioBlock>
				<RadioBlock
					label="Робота РЕЦЕПЦІЇ діагностичного центру<br> (1 - найнижчий бал, 10 - найвищий бал)"
					name="so3"
					v-model="formData.selectedOption3"

				>
				</RadioBlock>
				<RadioBlock
					label="КОМФОРТНІСТЬ ПЕРЕБУВАННЯ в холі діагностичного центру<br> (1 - найнижчий бал, 10 - найвищий бал)"
					name="so4"
					v-model="formData.selectedOption4"
				>
				</RadioBlock>
				<RadioBlock
					label="Робота МЕДИЧНИХ СЕСТЕР /МЕДИЧНИХ БРАТІВ, які спілкувались з Вами на етапі підготовки, під час та після обстеження<br> (1 - найнижчий бал, 10 - найвищий бал)"
					name="so5"
					v-model="formData.selectedOption5"
				>
				</RadioBlock>
				<RadioBlock
					label="Рівень Вашого відчуття БЕЗПЕКИ під час проходження обстеження<br> (1 - було відчуття НЕБЕЗПЕКИ, 10 - почувався /почувалася АБСОЛЮТНО БЕЗПЕЧНО)"
					name="so6"
					v-model="formData.selectedOption6"
				>
				</RadioBlock>
				<RadioBlock
					label="ЗРУЧНІСТЬ процесу ОТРИМАННЯ РЕЗУЛЬТАТІВ обстеження в М24<br> (1 - зовсім незручно, 10 - дуже зручно)"
					name="so7"
					v-model="formData.selectedOption7"
				>
				</RadioBlock>
				<RadioBlock
					label="* Чи порекомендували б Ви діагностичний центр М24 (МРТ та КТ) Вашим близьким та друзям?<br> (1 - не рекомендую, 10 - абсолютно рекомендую)"
					name="so8"
					v-model="formData.selectedOption8"
					:error-message="!formData.selectedOption8 && isFieldRequired('selectedOption8') && stepValidate ? 'Відповідь на це запитання обов\'язкова' : ''"
				>
				</RadioBlock>
				<Textarea
					label="Додатковий коментар про роботу Діагностичних центрів М24 або Ваші рекомендації щодо покращення:"
					v-model="formData.question2"
				>
				</Textarea>


				<div class="buttongroup">
					<button @click="stepPrevius">назад</button>
					<button @click="validateStep">вперед</button>
				</div>

			</div>
		</div>



	</div>
</template>
<script>
import RadioBlock from '@/components/RadioBlock.vue';
import Textarea from '@/components/TextareaBlock.vue';
export default {
  name: 'Step1',
	components: {
		RadioBlock,
		Textarea
	},
	props: ['formData'],
	data() {
		return {
			stepValidate: false,
			tempval: [],
			reqvalidate: ['selectedOption8']
			// formData: {
      //   selectedOption1: '',
      //   selectedOption2: '',
      //   selectedOption3: '',
      // },
		}
	},
	methods: {
		isFieldRequired(fieldName) {
      return this.reqvalidate.includes(fieldName);
    },
		stepPrevius() {
			this.$emit('prev-step')
		},
		validateStep() {
			this.stepValidate = true

			const countValidate = this.reqvalidate.reduce((acc, item) => {
				let formData = this.formData
				if (formData[item]) {
					return acc + 1;
				} else {
					return acc;
				}
			}, 0);
			if (countValidate === this.reqvalidate.length) {
				this.$emit('next-step', this.formData)
			}

    },
	}
}
</script>
<style lang="sass" scoped>
.buttongroup
	justify-content: space-between
</style>